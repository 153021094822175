import * as dayjs from 'dayjs'
import i18n from './i18n'

export default {
  data: {
    updatedAt: dayjs().format("YYYY-MM-DD"),
    accepted_terms: false,
    hltid: null,
    coupons: [],
    cart: {
      quantity: 1,
      value: 100,
      subtotal: 100,
      discount: 0,
      currency: null,
      total: 100,
      locale: null,
      country: null,
      deliverance_date: dayjs().format("YYYY-MM-DD"),
      send_by_email: true,
      send_by_sms: false,
      personal_headline: i18n.t("Hi"),
      personal_note: i18n.t("With this you get a gift card to renew and expand your wardrobe with all the season's latest colors and styles.\n\nGood fun!"),
    },
    receiver: {
      first_name: "",
      last_name: "",
      email: "",
      email_confirm: "",
      phone: "",
    },
    sender: {
      first_name: "",
      last_name: "",
      email: "",
    },
  },

  save(data) {
    data.updatedAt = dayjs().format("YYYY-MM-DD");
    localStorage.setItem("order", JSON.stringify(data));
  },

  clear() {
    localStorage.removeItem("order");
  },

  load(key) {
    let currentOrder = this.data
    let savedOrder = localStorage.getItem("order")

    if (savedOrder) {
      try {
        currentOrder = JSON.parse(savedOrder);
      } catch (e) {
        localStorage.removeItem("order");
      }
    }

    // if (dayjs(currentOrder.cart.deliverance_date).isBefore(dayjs(), 'day')) {
    //   currentOrder.cart.deliverance_date = dayjs().format("YYYY-MM-DD")
    // }

    if (dayjs(currentOrder.cart.deliverance_date).isBefore(dayjs(), 'day')) {
      currentOrder.cart.deliverance_date = dayjs().format("YYYY-MM-DD")
    }

    if (key) {
      return currentOrder[key] ?? null
    } else {
      return currentOrder
    }
  }
}
