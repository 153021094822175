<template>
  <div class="flex flex-wrap mb-2">
    <div v-if="label" class="w-full mb-2">
      <label class="form-label" :for="id">
        {{ label }}
      </label>
    </div>

    <div class="w-full">
      <div v-if="error" class="form-error text-right mb-2">
        {{ error }}
      </div>

      <div class="flex items-center">
        <input :id="id" ref="input" v-bind="$attrs" class="form-input w-full" :class="{ error: error, 'rounded-r': !postfix }" :type="type" :value="value" @input="$emit('input', $event.target.value)" :readonly="readonly" :step="step" :min="min" v-on:keyup.enter="$emit('submit', $event.target.value)" />
        <div v-if="postfix" class="px-2 py-3 block text-gray-800 bg-gray-200 text-left rounded-r border h-11">
          {{ postfix }}
        </div>
      </div>
      <div v-if="help" class="text-xs text-left mt-2 ml-1">{{ help }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${this._uid}`;
      },
    },
    type: {
      type: String,
      default: "text",
    },
    value: [String, Number],
    label: String,
    postfix: String,
    help: String,
    step: [String, Number],
    min: [String, Number],
    error: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
  },
};
</script>
