<template>
  <div class="container mx-auto">
    <div class="terms">
      <strong>Generelle bestemmelser</strong>
      <p>Disse vilkår og betingelser beskriver rammerne for de kommercielle forhold, der kan opstå mellem kunden og LoyaLK Systems ApS, når kunden bruger onlinebutikken (Zalando gavekortportal).</p>
      <p>Når kunden har indgået en aftale om køb af en vare eller et værdibevis (herefter vare/værdibevis) (til fx en oplevelse) hos LoyaLK Systems ApS, opbevarer LoyaLK Systems ApS oplysninger om ordren i 5 år.</p>
      <p>Kunden kan se disse oplysninger ved at logge ind under profilsektionen. Al information omkring et produkt eller en samarbejdspartner er beskrevet og gengivet så fyldestgørende som muligt.</p>
      <strong>1. Virksomhedsinformation</strong>
      <p class="text-center">
        Loyalty Key ApS
        <br />
        Dampfærgevej 21
        <br />
        DK-2100 København Ø
        <br />
        +45 70 20 05 90
        <br />
        CVR nr.: 33879091
        <br />
        E-mail: <a href="mailto:support@loyaltykey.com">support@loyaltykey.com</a>
      </p>
      <strong>2. Accept</strong>
      <p>Ved at bruge Zalando gavekortportal og tjenesterne giver du dit ubetingede samtykke til de underliggende vilkår og betingelser.</p>
      <strong>3. Kontakt og support</strong>
      <p>
        Loyalty Key tilbyder support alle hverdage. Supportkommunikationen foregår direkte imellem supportafdelingen og kunden. Spørgsmål besvares typisk indenfor 24 timer. E-mail:
        <a href="mailto:support@loyaltykey.com">support@loyaltykey.com</a>
      </p>
      <strong>4. Alder</strong>
      <p>Kunden skal være minimum 18 år for at handle på Zalando gavekortportal. Ved bestilling af varer/oplevelser på Zalando gavekortportal bekræfter kunden, at kunden er 18 år eller derover. Loyalty Key kan ikke holdes til ansvar, hvis en mindreårig handler under falske oplysninger.</p>
      <strong>5. Bestillingsprocessen</strong>
      <p>
        Varen/værdibeviset købes online via Zalando gavekortportal, i en nærmere angivet periode eller indtil samtlige antal varer/værdibeviser er udsolgt. På Loyalty Key's hjemmeside er der anført i hvilken periode, varen/værdibeviset kan erhverves, og om der gælder et maksimum for det antal varer/værdibeviser, der sælges i den pågældende periode.
        <br />
        Ved bestillingen udfyldes navn, adresse, e-mailadresse, og der vælges betalingsmåde.
      </p>
      <p>Derefter kan kunden fuldende betalingen ved at udfylde betalingsoplysningerne. Kort efter betalingen vil kunden modtage et ordrenummer per e-mail. Ved al kontakt til Loyalty Key, er det vigtigt at have ordrenummeret klar. Salget af varen/værdibeviset sker først hvis det lykkes Loyalty Key at hæve betalingen. Skulle betalingen ikke lykkes ved første forsøg (f.eks. fordi den angivne konto ikke eksisterer, mangler dækning, betalingskortnummeret er forkert, eller har nået kreditgrænsen), vil kundens ret til en vare/værdibevis ikke finde sted.</p>
      <p>Når købet er gennemført, vil ordrebekræftelsen på varen eller værdibeviset blive sendt per e-mail til den e-mail-adresse, som kunden angiver i forbindelse med bestillingen, senest 24 timer efter at købet er afsluttet. Afsendelsen af varerne/værdibeviserne sker umiddelbart efter, at betalingen er registreret hos Loyalty Key.</p>
      <p>Er der tale om et værdibevis til en oplevelse skal værdibeviset indløses hos den relevante samarbejdspartner indenfor den anførte tidsperiode. Såfremt det ikke er muligt at efterkomme kundens bestilling, vil Loyalty Key informere herom hurtigst muligt. Kunden har på denne baggrund ret til at annullere sin bestilling.</p>
      <p>Zalando Gavekortportal er en online shop, der har åbent 24 timer i døgnet, men kan dog være lukket som følge af vedligeholdelse. En bestilling som er afgivet på en offentlig fri- eller helligdag, vil blive behandlet den følgende hverdag.</p>
      <p>Loyalty Key forbeholder sig retten til at begrænse bestillingsmulighederne og til at annullere enhver ordre efter eget skøn, i tilfælde af misbrug, eller når der opstår en tvist, eller en tidligere tvist er opstået med en kunde, eller når de bestilte eller returnerede mængder er unormalt høje.</p>
      <strong>6. Betalingsbetingelser</strong>
      <p>
        Et køb betales online ved brug af gængse betalingskort, så som Dankort, VISA/Dankort, VISA, Mastercard, Maestro, MobilePay og Diners Club.
        <br />
        Betalingen reserveres straks på kundens betalingskort, når kunden har gennemført sin bestilling. Købet er først gennemført, hvis og når det lykkes Loyalty Key at registrere betalingen. Skulle betalingen ikke lykkes ved første forsøg (f.eks. fordi den angivne kortkonto ikke eksisterer, ikke har dækning, kreditkortnummeret er forkert eller kortet har nået kreditgrænsen), gennemføres købet ikke. Der kan aldrig trækkes et større beløb end aftalt.
        <br />
        Kundens kortinformationer lageres ikke hos Loyalty Key.
      </p>
      <p>Loyalty Key benytter STRIPE til håndteringen af alle onlinebetalinger. STRIPE / Payment Solutions er PCI certificeret af VISA / Mastercard, og al kommunikation foregår krypteret via en sikker forbindelse. Dette er med til at sikre at alle transaktioner sker i et sikkert miljø, som gør at ingen kan se de data som indgives i forbindelse med betalingen.</p>
      <strong>7. Priser & Betaling</strong>
      <p>
        Loyalty Key fastsætter prisen for ydelser i henhold til aftale med samarbejdspartneren. Alle anførte priser er vejledende udsalgspriser og er inkl. moms, i det omfang der er tale om momspligtige ydelser.
        <br />
        Når betalingen er gennemført, fremsender Loyalty Key ordrebekræftelsen til den e-mailadresse, kunden har opgivet, medmindre andet er anført. Hvis betalingen mislykkedes, orienterer Loyalty Key herom. Derefter kan kunden prøve at foretage betalingen igen, medmindre salgsperioden allerede er udløbet.
        <br />
        Hvis kundens betaling først modtages af Loyalty Key efter salgsperioden er udløbet, er der ikke indgået en aftale omkring køb af vare/værdibevis. I så fald bliver kundens betaling refunderet. Skulle der mod forventning være sket en trykfejl eller prisfejl, bestræber Loyalty Key sig på hurtigst muligt at kontakte kunden. Loyalty Key vil selvfølgelig altid forsøge at imødekomme en tilfredsstillende løsning for kunden.
      </p>
      <strong>8. Tilgængelighed</strong>
      <p>Produkterne sælges i begrænsede mængder. Varerne og priserne er gyldige, som de vises på Zalando gavekortportal og tilgængelighed afhænger af varelageret hos vores samarbejdspartner.</p>
      <p>Loyalty Key bestræber sig på at opfylde alle ordrer. Loyalty Key kan ikke holdes ansvarlig for manglende tilgængelighed af en vare hos samarbejdspartneren eller for eventuel skade, der måtte opstå som følge heraf.</p>
      <p>I tilfælde af at én eller flere varer i en ordre ikke er tilgængelige, kontakter vi dig via e-mail eller telefon om, at din ordre vil blive helt eller delvist annulleret.</p>
      <strong>9. Forsendelse</strong>
      <p>Værdibeviserne eller ordrebekræftelsen på en vare vil blive fremsendt inden for 24 timer efter betingelserne for handlen er opfyldt. For varer som sendes til kunden fremgår leveringstiden og fragtpriser af betingelserne på den pågældende kampagne.</p>
      <p>Forsendelsestider kan variere fra leverandør til leverandør, og eventuelle garantier eller erklæringer vedrørende leveringstider er underlagt eventuelle forsinkelser opstået som følge af leverandørforsinkelser, postforsinkelser eller force majeure, som vi ikke kan stilles til ansvar for. Bestiller du varer fra forskellige leverandører, vil dine varer blive leveret separat.</p>
      <strong>10. Fortrydelsesret</strong>
      <p>Vær opmærksom på, at gavekortet ikke kan benytte på alle produkter hos Zalando. Du kan se hvad gavekortet kan bruges på <a href="https://www.zalando.dk/faq/Rabatkoder-og-gavekort/hvad-kan-jeg-bruge-mit-gavekort-til.html" style="color:blue;text-decoration: underline;">her</a></p>
      <p>Som udgangspunkt har kunden 14 dages fortrydelsesret, når kunden handler på nettet. For fysiske varer gælder 14 dage fra varens modtagelse. For værdibeviser løber fristen fra den dato kunden modtager værdibeviset på mail. Kunden skal inden 14 dage fra modtagelse af værdibevis eller den fysiske vare, give Loyalty Key meddelelse om, at kunden ønsker at fortryde sit køb. Meddelelsen skal gives ved at kontakte Loyalty Key's kundeservice på tlf.: +45 70 20 05 90 eller per mail: support@loyaltykey.com</p>
      <strong>10.1. Undtaget af fortrydelsesretten</strong>
      <p>
        Der er ingen fortrydelsesret ved køb af værdibeviser til f.eks. arrangementer eller events, som foregår på en bestemt dato eller inden for en bestemt periode, eftersom disse ydelser er undtaget fortrydelsesretten i henhold til Forbrugeraftaleloven § 18, stk. 2, nr. 12. om værdiforringelse.
        <br />
        Det er ikke muligt at fortryde et køb efter værdibeviset er blevet indløst hos en af Loyalty Key's samarbejdspartnere.
      </p>
      <strong>10.2 Tilbagebetaling af købsbeløbet</strong>
      <p>
        Hvis kunden benytter sin fortrydelsesret, refunderer Loyalty Key betalingen modtaget fra kunden, uden unødig forsinkelse og under alle omstændigheder senest 14 dage fra den dato, hvor Loyalty Key har modtaget meddelelsen om kundens fortrydelse. Loyalty Key gennemfører en tilbagebetaling til kundens bankkonto på det betalingskort, som købet er foretaget på. Loyalty Key kan tilbageholde betalingen, indtil Loyalty Key har modtaget bekræftelse fra samarbejdspartneren, om at varen er modtaget retur.
        <br />
        Fortrydelse af en vare sker på de vilkår, som er fastsat af den pågældende udbyder af varen, og er Loyalty Key uvedkommende. Inden bestilling af en vare, bør kunden derfor sætte sig særskilt ind i vareudbyders vilkår for fortrydelse og reklamation. Dette vil fremgå af kampagnen. Fortrydelsesfristen for varen løber fra den dag, hvor varen modtages. Ved returnering sendes varen direkte retur til afsenderadressen. Dette skal ske inden 14 dage efter at kunden har givet os besked om, at kunden ønsker at benytte sig af sin fortrydelsesret.
      </p>
      <strong>11. Reklamation</strong>
      <p>Der gives efter købeloven en reklamationsret i 24 måneder på alle varer. Det er dog en forudsætning, at disse mangler eller fejl ikke er opstået som følge af fejlagtig brug af produktet eller anden skadeforvoldende adfærd. Du skal reklamere indenfor rimelig tid, hvilket vil sige, inden to måneder efter du har konstateret manglen eller fejlen. Ved berettiget reklamation betaler vi omkostningerne og bytter produktet.</p>
      <strong>12. Indløsning af et værdibevis hos Loyalty Key's samarbejdspartnere</strong>
      <p>
        Til oplevelser eller arrangementer udstedes et værdibevis, som kan anvendes hos vores samarbejdspartnere. Hvis ikke andet er angivet, er værdibeviset gyldigt i 3 år fra udstedelsen. Hvis værdibeviset vedrører en vare/tjenesteydelse som kun udbydes af samarbejdspartneren i en bestemt periode (for eksempel et arrangement/event), vil værdibevisets gyldighed automatisk være begrænset hertil. Alle værdibeviser er forsynet med unikke kodenumre. Samarbejdspartneren skal bruge disse kodenumre, når kunden indløser sit værdibevis. Kopiering af eller manipulering med værdikuponerne er ikke tilladt. Ved tilfælde af mistanke om kopiering eller manipulering forbeholder Loyalty Key sig retten til at videregive de relevante indsamlede oplysninger til den respektive samarbejdspartner eller offentlig myndighed.
        <br />
        <br />
        Værdibeviset er ikke personligt og kan overdrages til tredjemand. Loyalty Key forbeholder sig retten til i særlige tilfælde at indstille salget af værdibeviser og/eller tilbagekalde allerede købte værdibeviser. I så fald orienterer Loyalty Key kunden herom og godtgør udgiften til værdibeviset.
      </p>
      <strong>13. Ophør af profil og kundeforhold</strong>
      <p>Ved ophør af kundens kundeforhold til Loyalty Key, det være sig ved kundens eller Loyalty Key's opsigelse eller ophævelse, vil samtlige af kundens data på Zalando gavekortportal blive slettet senest 14 dage efter det endelige ophør.</p>
      <strong>14. Driftssikkerhed og tilgængelighed</strong>
      <p>Loyalty Key bestræber sig på at være tilgængelig for websitets brugere 24 timer i døgnet, men kan ikke garantere, at sitet til enhver tid er i fuld funktion og fejlfri. Loyalty Key kan ikke drages til ansvar for eventuelle uheld som følge af benyttelsen af Zalando gavekortportal , eksempelvis skader på computersystem eller tab af data. Kunden er selv ansvarlig for det tekniske udstyr og software, som kræves for at kunne benytte websitet, herunder internetadgang, browser, e-mail m.m. Loyalty Key fraskriver sig ligeledes ansvaret for enhver form for tab forårsaget ved anvendelsen af Loyalty Key service, uden at de tekniske kravspecifikationer og supplerende vejledninger følges.</p>
      <strong>15. Ansvarsbegrænsning og forbehold</strong>
      <p>
        Kundens brug af Loyalty Key's ydelser sker i enhver henseende på kundens eget ansvar og risiko. Loyalty Key er ikke forpligtet til at udbetale erstatning for eventuelle tab under følgende omstændigheder:
        <br />
        - Værdibeviset er ikke blevet indløst hos samarbejdspartneren.
        <br />
        - Varen eller tjenesteydelsen modsvarer ikke kundens forventninger. (LU)
        <br />
        - Samarbejdspartnerens konkurs.
      </p>
      <strong>16. Tredjemands rettigheder</strong>
      <p>
        Kunden er over for Loyalty Key ansvarlig for, at kundens data, m.v. ikke krænker tredjemands rettigheder.
        <br />
        Såfremt der foretages retsskridt over for kunden eller Loyalty Key med påstand om, at Loyalty Key's ydelser krænker tredjemands rettigheder er Loyalty Key berettiget til efter eget valg at ændre sine ydelser, således at krænkelsen ophører, eller ophæve denne aftale helt eller delvist uden yderligere ansvar for Loyalty Key. I begge tilfælde er Loyalty Key berettiget til at overtage sagen og de med sagen forbundne omkostninger. Loyalty Key er bemyndiget til at gennemføre sagen eller afslutte denne forligsmæssigt.
      </p>
      <strong>17. Kundeoplysninger</strong>
      <p>
        Ved kundens oprettelse af en profil hos Loyalty Key registreres kundeoplysninger, herunder særligt navn, adresse og e-mail, i Loyalty Key's kundedatabase. Loyalty Key's behandling af sådanne oplysninger er omfattet af og vil ske i overensstemmelse med lov om behandling af personoplysninger.
        <br />
        Direktøren og betroede medarbejdere for Loyalty Key har adgang til oplysninger, der registreres om kunden. Den dataansvarlige på Zalando gavekortportal er Loyalty Key. Loyalty Key er berettiget til at benytte eksterne leverandører af forskellige tjenesteydelser, der kræver udveksling af kundeoplysninger for korrekt udførelse af den pågældende ydelse. Alle Loyalty Key's samarbejdspartnere skal efter afsluttet leverance slette samtlige informationer. Loyalty Key fraskriver sig ansvar for den enkelte leverandørs eller samarbejdspartners misligholdelse af dette forhold. Alle leverandører og samarbejdspartnere er gjort opmærksom på Loyalty Key's persondatapolitik samt forretningsbetingelser.
        <br />
        Som registreret hos Loyalty Key har kunden altid ret til at gøre indsigelse mod registreringen. Kunden har også ret til indsigt i, hvilke oplysninger der er registreret om kunden. Disse rettigheder har kunden efter Persondataloven. Henvendelse i forbindelse hermed rettes til Loyalty Key via e-mail: support@loyaltykey.com.
      </p>
      <strong>18. Ved fejl eller problemer</strong>
      <p>Opstår der fejl eller problemer med varen/værdibeviset eller indløsningen heraf, vil Loyalty Key hjælpe med at finde en løsning. Kunden kan i sådanne tilfælde kontakte Loyalty Key's kundeservice på e-mail: support@loyaltykey.com eller på tlf.: +45 70 20 05 90.</p>
      <strong>19. Mailingliste</strong>
      <p>Kommunikation fra Loyalty Key til kunden er i et vist omfang automatiseret og vil som udgangspunkt foregå via e-mail. Det er derfor en forudsætning for at være kunde, at man accepterer at modtage e-mails fra Loyalty Key. Ved at acceptere nærværende Forretningsbetingelser samtykker kunden derfor udtrykkeligt til at blive tilmeldt Loyalty Key's mailingliste og derved løbende via e-mail modtage Loyalty Key's kampagner, informations- og nyhedsbreve. Kunden er dog berettiget til at afmelde sig markedsføringsmateriale fra Loyalty Key via kontoindstillingerne support@loyaltykey.com.</p>
      <strong>20. Kundens behandling og håndtering af personoplysninger og andre data</strong>
      <p>
        Kunden beslutter og bærer ansvaret for, hvilke eventuelle personoplysninger og data der registreres på kundens konto og/ eller ved køb af en vare/værdibevis og hvorledes disse skal anvendes. Loyalty Key bruger cookies til at føre demografiske og brugerrelaterede statistikker og for at sikre en alsidig visning af reklameformater. Til brug heraf registrerer vi udelukkende informationer af teknisk karakter som browsertype, IP-numre m.v. Ved alle reklameformater registrerer vi, om kunden klikker på de leverede annoncer.
        <br />
        Ingen personlige oplysninger om kundens færden på Zalando gavekortportal lagres.
      </p>
      <strong>21. Strafbare forhold</strong>
      <p>Såfremt Loyalty Key bliver vidende om strafbart forhold eller aktiviteter i forbindelse med en kundes tilhør, vil Loyalty Key ud over at ophæve kundens konto med øjeblikkeligt varsel, videregive alle relevante oplysninger til de danske myndigheder, i det omfang der er påkrævet.</p>
      <strong>22. Ændring af betingelser</strong>
      <p>Loyalty Key har til enhver tid ret til at ændre nærværende betingelser. De gældende betingelser vil altid fremgå på Zalando gavekortportal.</p>
      <strong>23. Lovvalg og værneting</strong>
      <p>Forretningsbetingelserne, herunder kundens brug af Zalando gavekortportal , er undergivet dansk ret og eventuelle tvister og uoverensstemmelser mellem kunden og Loyalty Key skal afgøres ved retten i København.</p>
      <strong>24. Force Majeure</strong>
      <p>Hverken Loyalty Key eller samarbejdspartneren er ansvarlige for manglende opfyldelse af sine forpligtelser i henhold til aftalen, hvis den manglende opfyldelse af handlen skyldes force majeure eller tvingende omstændigheder. Såfremt parterne ikke ved aftalens underskrift har taget hindringen i betragtning eller senere kunne have undgået den.</p>
      <strong>25. Uafhentet vare</strong>
      <p>I visse tilfælde bliver uafhentede varer sendt tilbage til Loyalty Key. Hvis kunden ønsker, at Loyalty Key skal gensende varen, skal kunden tage kontakt til Loyalty Key kundeservice. Det koster 65 kr. i fragt / ekspeditionsgebyr at få gensendt en vare, og pengene skal overføres til Loyalty Key, før varen bliver gensendt. I tilfælde af at en uafhentet vare sendes direkte tilbage til samarbejdspartneren, kan Loyalty Key tage kontakt til samarbejdspartneren for at indgå en ny aftale om gensendelse af den uafhentede vare. Loyalty Key er ikke ansvarlig for forhandlerens gensendelsespris.</p>
      <strong>26. Klageadgang</strong>
      <p>Hvis kunden vil klage over sit køb, skal kunden rette henvendelse til Loyalty Key på support@loyaltykey.com. Hvis det ikke lykkes os at finde en løsning, kan du sende en klage til:</p>
      <p class="text-center">
        Nævnenes Hus
        <br />
        Toldboden 2
        <br />
        8800 Viborg
        <br />
        naevneneshus.dk
      </p>
      <p class="text-center">Se godkendte klageinstanser her: http://www.forbrug.dk/Menu/Klageinstanser</p>
    </div>
  </div>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "TermsGeneral",
  layout: Layout
};
</script>
