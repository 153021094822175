<template>
  <div class="h-screen flex flex-col">
    <header class="py-8 bg-gray-100 border-b border-gray-300">
      <div class="mx-auto container">
        <div class="w-36 ml-5 lg:w-80 lg:ml-0">
          <img src="/images/zalando-logo.png" />
        </div>
      </div>
    </header>

    <div class="pt-10">
      <div class="p-10 text-center container mx-auto">
        <h1 class="font-bold text-lg mb-5">{{ title }}</h1>
        <div>{{ description }}</div>
      </div>
    </div>

    <footer class="px-5 mt-auto mb-0">
      <div class="border-t border-gray-300"></div>
      <div class="container mx-auto">
        <div class="flex flex-wrap py-16 text-center">
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <img class="w-12 h-12 mx-auto mb-5" src="/images/giftcard.svg" />
            <p class="text-xl font-bold mb-2">{{ $t("Choose gift card") }}</p>
            <p class="font-semibold">{{ $t("Order in a few minutes") }}</p>
          </div>
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <img class="mx-auto mb-5 inline-block" src="/images/mastercard.png" />
            <img class="mx-auto mb-5 inline-block" src="/images/visa-small-png.png" />
            <p class="text-xl font-bold mb-2">
              {{ $t("Pay easily and safely") }}
            </p>
            <p class="font-semibold">
              {{ $t("Pay with Visa and Mastercard") }}
            </p>
          </div>
          <div class="w-full sm:w-1/3">
            <img class="w-12 h-12 mx-auto mb-5" src="/images/receive.svg" />
            <p class="text-xl font-bold mb-2">{{ $t("Receive the code") }}</p>
            <p class="font-semibold">
              {{ $t("You will receive the code immediately") }}
            </p>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-300"></div>

      <div class="container mx-auto">
        <div class="flex flex-wrap text-center text-sm py-10 justify-center">
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <p class="py-1">LK Systems ApS</p>
            <p class="py-1">Kristianiagade 1, 2100 København Ø</p>
            <p class="py-1">CVR nr.: 33879091</p>
          </div>
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <p class="py-1">{{ $t("Contact us at") }}:</p>
            <p class="py-1">{{ $t("Email") }}: support@loyaltykey.com</p>
            <p class="py-1">{{ $t("Telephone") }}: +45 70200591</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Error",

  props: {
    status: Number,
  },

  computed: {
    title() {
      return {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        404: "404: Page Not Found",
        403: "403: Forbidden",
      }[this.status];
    },
    description() {
      return {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
      }[this.status];
    },
  },
};
</script>
