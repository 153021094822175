<template>
  <div>
    <div>
      <label v-if="label" class="form-label" :for="id">{{ label }}</label>
      <input
        :id="id"
        ref="input"
        v-bind="$attrs"
        class="form-checkbox"
        :class="{ error: error }"
        type="checkbox"
        :checked="checked"
        @input="$emit('input', $event.target.checked)"
        :readonly="readonly"
        :disabled="disabled"
      />
    </div>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${this._uid}`;
      },
    },
    checked: Boolean,
    label: String,
    error: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
