<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-5">
      <loading :active.sync="sending"></loading>

      <div class="bg-gray-100 p-10 mb-5 sm:mb-0">
        <h2 class="font-semibold my-5">
          {{ $t("To whom should the gift card be sent") }}
        </h2>

        <text-input v-model="order.receiver.first_name" :error="errors['receiver.first_name']" required :label="$t('First name') + '*'" />
        <text-input v-model="order.receiver.last_name" :error="errors['receiver.last_name']" :label="$t('Surname')" />
        <text-input v-model.string="order.receiver.email" :error="errors['receiver.email']" type="email" required :label="$t('Email') + '*'" />
        <text-input v-model.string="order.receiver.email_confirm" :error="errors['receiver.email_confirm']" type="email" required :label="$t('Repeat Email') + '*'" />
        <!-- <text-input v-model.number="order.receiver.phone" :error="errors['receiver.phone']" type="tel" :label="$t('Telephone')" /> -->

        <h2 class="font-semibold mt-5 mb-5">
          {{ $t("Sender information") }}
        </h2>

        <text-input v-model="order.sender.first_name" :error="errors['sender.first_name']" required :label="$t('First name') + '*'" autocomplete="given-name" />
        <text-input v-model="order.sender.last_name" :error="errors['sender.last_name']" :label="$t('Surname')" autocomplete="family-name" />
        <text-input v-model.string="order.sender.email" :error="errors['sender.email']" type="email" required :label="$t('Email') + '*'" :help="$t('You will receive a receipt at the email address entered.')" autocomplete="email" />
      </div>

      <div class="bg-gray-100 p-10">
        <!-- <h1 class="text-center text-xl font-semibold mb-5">{{ $t("Overview") }}</h1> -->

        <div class="mb-5 bg-white text-center p-4 rounded">
          <img src="https://cdn.worldvectorlogo.com/logos/zalando-2.svg" class="inline-block h-8" />
          {{ $t("gift card") }}
          <div class="text-xs">{{ $t("Europe's largest fashion platform") }}</div>
          <div class="text-center font-medium bg-white pt-4">{{ order.cart.quantity }} stk. á {{ order.cart.value | formatCurrency }}</div>
        </div>

        <div class="mt-5 mb-5">
          <div v-if="order.cart.discount" class="mb-5">
            <div class="flex justify-between mb-2">
              <div class="text-gray-700">{{ $t("Subtotal") }}</div>
              <div>
                {{ order.cart.subtotal | formatCurrency }}
              </div>
            </div>

            <div class="flex justify-between mb-5">
              <div class="text-gray-700">{{ $t("Rabat") }}</div>
              <div>-{{ order.cart.discount | formatCurrency }}</div>
            </div>

            <hr class="hr" />
          </div>

          <div class="flex justify-between text-xl font-semibold mb-5">
            <div>{{ $t("Total price") }}</div>
            <div>{{ order.cart.total | formatCurrency }}</div>
          </div>

          <div class="flex justify-between text-xl font-semibold">
            <div>{{ $t("Delivery Date") }}</div>
            <div>{{ order.cart.deliverance_date | formatDate }}</div>
          </div>
        </div>

        <div class="border-zalandoOrange border-b-2 mb-5"></div>

        <h1 class="text-xl font-semibold mb-5">{{ $t("Terms and Conditions") }}</h1>

        <p class="mb-5">
          {{ $t("By placing an order, I agree that the contract will take effect with immediate effect so that the right of withdrawal no longer applies.") }}
        </p>

        <div class="mb-8">
          <div>
            <checkbox-input v-model="order.accepted_terms" class="inline-block" :checked="order.accepted_terms" />
            <a v-on:click="showTerms" href="#">
              {{ $t("Read and accept our terms and conditions") }}
              <u>{{ $t("here") }}</u>
            </a>
          </div>
          <div v-if="errors['accepted_terms']" class="form-error mt-2">
            {{ errors["accepted_terms"] }}
          </div>

          <div v-if="errors['cart.total']" class="form-error mt-2">
            {{ errors["cart.total"] }}
          </div>
        </div>

        <div class="text-center">
          <a href="#" v-on:click="submit" class="button text-white">
            {{ $t("Proceed to payment") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import LoadingButton from "@/Shared/LoadingButton";
import CheckboxInput from "@/Inputs/CheckboxInput";
import TextInput from "@/Inputs/TextInput";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";

export default {
  name: "OrderCheckout",
  layout: Layout,

  components: {
    LoadingButton,
    CheckboxInput,
    TextInput,
    Loading
  },

  props: {
    errors: Object
  },

  data() {
    return {
      sending: false,
      order: store.load()
    };
  },

  mounted() {
    this.order.accepted_terms = false;

    // // Test data
    // this.order.accepted_terms = true;
    // this.order.cart.quantity = 3;
    // this.order.cart.value = 100;
    // this.order.cart.deliverance_date = this.$date().format("YYYY-MM-DD");
    // this.order.receiver.first_name = "Firstname Receiver";
    // this.order.receiver.last_name = "Lastname Receiver";
    // this.order.receiver.email = "receiver@loyaltykey.com";
    // this.order.receiver.email_confirm = "receiver@loyaltykey.com";
    // this.order.receiver.phone = "12345678";
    // this.order.sender.first_name = "Firstname Sender";
    // this.order.sender.last_name = "Lastname Sender";
    // this.order.sender.email = "sender@loyaltykey.com";
  },

  methods: {
    showTerms() {
      this.$swal({
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        },
        title: this.$t("Terms of Use"),
        html: this.$page.props.terms.general,
        showCloseButton: true
      });
    },

    submit() {
      this.sending = true;

      this.$inertia.post(this.route("order.store"), this.order, {
        onSuccess: response => {
          this.sending = false;
        },
        onError: errors => {
          this.sending = false;
        }
      });
    }
  },

  watch: {
    order: {
      deep: true,
      handler() {
        store.save(this.order);
      }
    }
  }
};
</script>
