import Vue from 'vue'
import { App, plugin } from '@inertiajs/inertia-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import i18n from './i18n'
import * as dayjs from 'dayjs'
import store from '@/store'

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(plugin)
Vue.use(VueSweetalert2)

Vue.prototype.$date = dayjs

const el = document.getElementById('app')
const settings = JSON.parse(el.dataset.page)

// Checkout setup & store
const order = store.load()

// First thing - save hltid and/or coupons if present
let urlParams = new URLSearchParams(window.location.search)
if (urlParams.has('hltid')) {
  order.hltid = urlParams.get('hltid')
  store.save(order)
}

if (urlParams.has('coupon')) {
  order.coupons = []
  order.coupons.push({
    'code': urlParams.get('coupon'),
    'value': 0,
    'type': '',
    'active': false,
  })
  store.save(order)
}

// Redirect if needed, cart details triumphs requested url
if (settings.props.channel) {
  if (settings.props.channel.country && order.cart.country != settings.props.channel.country) {
    let orderUrl = window.location.host + '/' + order.cart.locale + '-' + order.cart.country

    // All is good, the cart country and locale checks out
    if (settings.props.hosts.includes(orderUrl)) {
      window.location = '/' + order.cart.locale + '-' + order.cart.country
    } else {
      order.cart.locale = settings.props.locale
      order.cart.country = settings.props.channel.country
      order.cart.currency = settings.props.channel.currency
      store.save(order)
    }
  } else {
    if (order.cart.amount) {
      order.cart.quantity = order.cart.amount
      delete order.cart.amount
    }

    order.cart.locale = settings.props.locale
    order.cart.country = settings.props.channel.country
    order.cart.currency = settings.props.channel.currency
    store.save(order)
  }
}

// Global methods
Vue.mixin({
  methods: {
    route: (name, params, absolute) => route(name, params, absolute),
  },
})

// Global filters
Vue.filter('formatDate', function (value) {
  if (!value) return value
  return dayjs(value).format('DD-MM-YYYY')
})
Vue.filter('formatCurrency', function (value, currency, locale) {
  currency = currency ?? settings.props.channel.currency
  locale = locale ?? settings.props.locale
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value)
})

new Vue({
  i18n,
  render: h => h(App, {
    props: {
      initialPage: settings,
      resolveComponent: name => require(`./Pages/${name}`).default,
    },
  }),
}).$mount(el)
