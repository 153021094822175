<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-5">
      <div class="flex flex-col flex-1 bg-gray-100 p-10 pb-16 text-center mb-5 sm:mb-0">
        <h1 class="text-xl font-semibold pb-3">{{ $t("Zalando Gift Card") }}</h1>
        <h2 class="text-md font-semibold pb-3">
          {{ $t("Europe's largest fashion platform") }}
        </h2>

        <div class="pb-5">
          <div class="relative inline-block mb-3">
            <img src="/images/card.jpg" />
            <div class="absolute bottom-0 right-0 text-sm mb-4 md:mb-5 lg:font-semibold mr-2">{{ $t("Unit price") }}: {{ order.cart.value | formatCurrency }}</div>
          </div>
          <div class="mb-3">{{ order.cart.personal_headline }}</div>
          <div class=" mx-auto whitespace-pre-line leading-relaxed" style="overflow-wrap: break-word; max-width:350px;">
            {{ order.cart.personal_note }}
          </div>
        </div>

        <div class="mt-auto border-t pt-4 text-sm">
          <p class="mb-2 leading-relaxed">
            {{ $t("Make someone you love happy with a gift card for Zalando - Europe's largest fashion platform.") }}
          </p>
          <p class="mb-2 leading-relaxed">
            <!-- {{ $t("Here the recipient can choose freely between shoes, accessories, clothes and sporting goods from more than 2,500 different brands.") }}<br> -->
            <p>{{ $t("Here, the recipient can freely choose fashionable clothing from thousands of brands.") }}</p><br>
            <p style="font-size:20px; font-weight: 600; text-decoration: underline;">{{ $t("Please note the following conditions:") }}</p><br>
            <p>{{ $t("There are a few categories that do not fall under 'fashion,' such as:") }} </p><br>
            <p>{{ $t("Jewellery and watches, Home, lifestyle and electronics, Sports equipment, Travel bags and wallets, Beauty and makeup.") }}</p><br>
            <p><a class="underline" target="_blank" href="https://www.zalando.dk/vilkar-gavekort">{{ $t("Read more here") }}</a></p>
          </p>

          <p class="mb-2 leading-relaxed">
            {{ $t("The gift card will be sent directly to the recipient's email address on the selected delivery date.") }}
          </p>
          <p class="leading-relaxed">
            {{ $t("Please note that there is a DKK 1,500 limit per purchase. When you need to use the gift card at Zalando, you must also be aware that several gift cards can be redeemed per purchase up to a total maximum value of DKK 1,500. A possible remaining amount is made available for future purchases.") }}<br>
            {{ $t("The maximum value of GiftCards that can be used per month per end customer is limited to € 5.000. The maximum value of Gift Cards that can be connected to your customer account per calendar month is limited to € 5,000,-.") }}
          </p>
        </div>
      </div>

      <div class="bg-gray-100 p-10 pb-16">
        <div class="bg-red-300 p-3 rounded-md mb-5 text-sm" v-if="cancelled">
          {{ $t("You have canceled your order. You can create a new one below.") }}
        </div>

        <h2 class="font-semibold mt-5 mb-5">
          {{ $t("Add a personal greeting along with your gift card") }}
        </h2>

        <text-input v-model="order.cart.personal_headline" :error="errors['cart.personal_headline']" :label="$t('Headline')" class="mb-5" />
        <textarea-input v-model="order.cart.personal_note" :error="errors['cart.personal_note']" :label="$t('Message')" rows="7" class="mb-5" />
        <text-input v-model="order.cart.deliverance_date" :error="errors['cart.deliverance_date']" type="date" :min="$date().format('YYYY-MM-DD')" :label="$t('Delivery Date')" :help="$t('An email with the gift card will be sent on that date')" class="mb-5" />

        <hr class="hr" />

        <h3 class="font-semibold mt-5 mb-5">{{ $t("Select quantity and value") }}</h3>

        <text-input v-model.number="order.cart.value" :error="errors['cart.value']" class="mb-5" type="number" step="50" min="50" max="1500" :label="$t('Value')" :postfix="$page.props.channel.currency.toUpperCase()" />
        <text-input v-model.number="order.cart.quantity" :error="errors['cart.quantity']" class="mb-5" type="number" step="1" min="1" :label="$t('Quantity')" />

        <!-- <div>
          <checkbox-input v-model.boolean="order.cart.send_by_email" :error="errors.value" :label="$t('Send the gift card via email')" disabled="disabled" :checked="order.cart.send_by_email" />
          <div class="text-xs mt-2">{{ $t("NOTE: The gift card will always be sent via email") }}</div>
        </div> -->

        <div v-if="$page.props.channel.coupons">
          <hr class="hr" />

          <h3 class="font-semibold mt-5 mb-5">{{ $t("Do you have a discount code?") }}</h3>

          <div class="flex items-end">
            <text-input v-on:submit="applyCoupon" v-model="couponCode" :error="errors['couponCode']" :placeholder="$t('Discount code')" class="flex-grow" />
            <button v-on:click="applyCoupon" class="button bg-gray-400 hover:bg-gray-500 text-gray-900 mb-2 ml-2 h-11">{{ $t("Add") }}</button>
          </div>

          <div class="flex mb-5">
            <div v-for="(coupon, index) in order.coupons">
              <div class="tag" :class="{ error: !coupon.active }">
                <span>{{ coupon.code }}</span>
                <button v-on:click.prevent="removeCoupon(index)">
                  <svg class="w-6 h-6 fill-current mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z" /></svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr" />

        <div class="mt-5 mb-5">
          <div v-if="discount" class="mb-5">
            <div class="flex justify-between mb-2">
              <div class="text-gray-700">{{ $t("Subtotal") }}</div>
              <div>
                {{ subtotal | formatCurrency }}
              </div>
            </div>

            <div class="flex justify-between mb-5">
              <div class="text-gray-700">{{ $t("Discount") }}</div>
              <div>-{{ discount | formatCurrency }}</div>
            </div>

            <hr class="hr" />
          </div>

          <div class="flex justify-between text-xl font-semibold">
            <div>{{ $t("Total price") }}</div>
            <div>{{ total | formatCurrency }}</div>
          </div>
          <div v-if="errors['cart.total']" class="form-error mt-2 text-right">
            {{ errors["cart.total"] }}
          </div>
        </div>

        <button v-on:click="checkout" class="button">
          {{ $t("Continue to order") }}
        </button>
      </div>
    </div>
  </div>
</template>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
p{
  line-height: 1.6;
}
</style>

<script>
import Layout from "@/Shared/Layout";
import CheckboxInput from "@/Inputs/CheckboxInput";
import TextInput from "@/Inputs/TextInput";
import TextareaInput from "@/Inputs/TextareaInput";
import store from "@/store";
import debounce from "lodash.debounce";

export default {
  name: "OrderCreate",
  layout: Layout,

  components: {
    CheckboxInput,
    TextInput,
    TextareaInput
  },

  props: {
    cancelled: false,
    errors: Object
  },

  data() {
    return {
      sending: false,
      couponCode: null,
      order: store.load()
    };
  },

  mounted() {
    this.validateCoupons();
  },

  methods: {
    checkout() {
      this.$inertia.post(route("order.validate"), this.order, { preserveScroll: true });
    },

    removeCoupon(index) {
      this.order.coupons.splice(index, 1);
      delete this.errors.couponCode;
    },

    applyCoupon: debounce(function() {
      // If already in do not add again
      if (!this.couponCode) {
        return;
      }

      let hasCouponCode = false;

      if (this.order.coupons) {
        this.order.coupons.forEach(appliedCoupon => {
          if (appliedCoupon.code == this.couponCode) {
            hasCouponCode = true;
          }
        });
      }

      if (hasCouponCode) {
        this.couponCode = null;
        delete this.errors.couponCode;
        return;
      }

      axios
        .post(route("order.applyCoupon"), { code: this.couponCode, coupons: this.order.coupons })
        .then(response => {
          this.order.coupons = [];
          this.order.coupons.push(response.data);
          delete this.errors.couponCode;
        })
        .catch(error => {
          this.$set(this.errors, "couponCode", error.response.data.message);
        })
        .then(() => {
          this.couponCode = null;
        });
    }),

    validateCoupons() {
      if (!this.order.coupons || !this.order.coupons.length) {
        return;
      }

      axios
        .post(route("order.validateCoupons"), { coupons: this.order.coupons })
        .then(response => {
          this.order.coupons = response.data;
        })
        .catch((error, response) => {
          this.errors["couponCode"] = response["errors"].code[0];
        });
    }
  },

  watch: {
    order: {
      deep: true,
      handler() {
        this.order.cart.subtotal = this.subtotal;
        this.order.cart.discount = this.discount;
        this.order.cart.total = this.total;

        store.save(this.order);
      }
    }
  },

  computed: {
    subtotal() {
      return this.order.cart.quantity * this.order.cart.value;
    },

    discount() {
      let discount = 0;
      let subtotal = this.subtotal;

      if (!this.order.coupons || !this.order.coupons.length) {
        return discount;
      }

      this.order.coupons.forEach(coupon => {
        if (coupon.active) {
          if (coupon.type == "percent") {
            discount += (subtotal * coupon.value) / 100;
          } else if (coupon.type == "fixed") {
            discount += coupon.value;
          }

          subtotal = subtotal - discount;
        }
      });

      return discount;
    },

    total() {
      return this.order.cart.quantity * this.order.cart.value - this.discount;
    }
  }
};
</script>
