<template>
  <div class="h-screen">
    <header class="py-8 bg-gray-100 border-b border-gray-300" style="box-sh">
      <div class="mx-auto container flex justify-around items-center lg:justify-between">
        <div class="w-64 lg:w-80">
          <a :href="route('order.create')">
            <img v-if="$page.props.channel.logo" :src="$page.props.channel.logo" />
            <img v-else src="/images/zalando-logo.png" />
          </a>
        </div>

        <div v-if="$page.props.channel.locales.length > 1">
          <div v-for="locale in $page.props.channel.locales" v-if="$page.props.locale != locale">
            <a :href="locale + '-' + $page.props.channel.country + urlParams" v-on:click="clearLocal" class="flex uppercase">
              <img class="block w-4 mr-1" :src="'/images/flags/' + locale + '.svg'" />
              <p>{{ locale }}</p></a
            >
          </div>
        </div>
      </div>
    </header>

    <div class="flex flex-grow overflow-hidden bg-white pt-10">
      <div class="main flex-grow" scroll-region>
        <flash-messages />
        <slot />
      </div>
    </div>

    <footer class="px-5">
      <div class="border-t border-gray-300"></div>
      <div class="container mx-auto">
        <div class="flex flex-wrap py-16 text-center">
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <img class="w-12 h-12 mx-auto mb-5" src="/images/giftcard2.svg" />
            <p class="text-xl font-bold mb-2">{{ $t("Choose gift card") }}</p>
            <p class="font-semibold">{{ $t("Order in a few minutes") }}</p>
          </div>
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <img class="mx-auto mb-5 inline-block" src="/images/mastercard.png" />
            <img class="mx-auto mb-5 inline-block" src="/images/visa-small-png.png" />
            <p class="text-xl font-bold mb-2">
              {{ $t("Pay easily and safely") }}
            </p>
            <p class="font-semibold">
              {{ $t("Pay with Visa and Mastercard") }}
            </p>
          </div>
          <div class="w-full sm:w-1/3">
            <img class="w-12 h-12 mx-auto mb-5" src="/images/plane.svg" />
            <p class="text-xl font-bold mb-2">{{ $t("Receive the code") }}</p>
            <p class="font-semibold">
              {{ $t("You will receive the code immediately") }}
            </p>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-300"></div>

      <div class="container mx-auto">
        <div class="flex flex-wrap text-center text-sm py-10">
          <div v-if="$page.props.channel.company" v-html="$page.props.channel.company" class="w-full sm:w-1/3 mb-12 sm:mb-0"></div>
          <div v-else class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <p class="py-1">LK Systems ApS</p>
            <p class="py-1">Dampfærgevej 21, 2100 København Ø</p>
            <p class="py-1">CVR nr.: 33879091</p>
          </div>
          <div v-if="$page.props.channel.contact" v-html="$page.props.channel.contact" class="w-full sm:w-1/3 mb-12 sm:mb-0"></div>
          <div v-else class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <p class="py-1">{{ $t("Contact us at") }}:</p>
            <p class="py-1">{{ $t("Email") }}: support@loyaltykey.com</p>
            <p class="py-1">{{ $t("Telephone") }}: +45 70200591</p>
          </div>
          <div class="w-full sm:w-1/3 mb-12 sm:mb-0">
            <p class="py-1">
              <a href="#" class="py-1" v-on:click="showTerms">
                {{ $t("Terms and Conditions") }}
              </a>
              <!-- <inertia-link :href="route('terms.general')">
                Handelsbetingelser
              </inertia-link> -->
            </p>
            <p class="py-1">
              <a href="#" class="py-1" v-on:click="showUserTerms">
                {{ $t("Terms of Use") }}
              </a>
              <!-- <inertia-link :href="route('terms.privacy')">
                Brugerbetingelser
              </inertia-link> -->
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import FlashMessages from "@/Shared/FlashMessages";

export default {
  data() {
    return {
      urlParams: window.location.search
    };
  },
  components: {
    FlashMessages
  },

  methods: {
    clearLocal() {
      localStorage.removeItem("order");
    },
    showTerms() {
      this.$swal({
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        },
        title: "<strong>" + this.$t("Terms and Conditions") + "</strong>",
        html: this.$page.props.terms.general,
        showCloseButton: true
      });
    },

    showUserTerms() {
      this.$swal({
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        },
        title: "<strong>" + this.$t("Terms of Use") + "</strong>",
        html: this.$page.props.terms.privacy,
        showCloseButton: true
      });
    }
  }
};
</script>
