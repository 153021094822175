<template>
  <div class="container mx-auto">
    <div class="bg-gray-100 p-10">
      <h1 class="text-center text-xl font-semibold pb-3">
        {{ $t("We look forward to sending the gift card!") }}
      </h1>

      <div class="relative w-96 mx-auto mb-8">
        <img class="mx-auto pb-3" src="/images/card.jpg" />
        <div class="absolute bottom-8 right-8">{{ order.quantity }} stk. á {{ order.value | formatCurrency(order.currency) }}</div>
      </div>

      <div class="md:w-7/12 mx-auto">
        <div class="flex justify-between text-xl font-semibold mb-5">
          <div>{{ $t("Order ID:") }}</div>
          <div>
            {{ order.order_id }}
          </div>
        </div>

        <div class="mb-5">
          <div class="flex justify-between text-xl font-semibold">
            <div>
              {{ $t("The gift card will be sent on") }}
            </div>
            <div>{{ order.deliverance_date | formatDate }}</div>
          </div>
          <div v-if="$date(order.deliverance_date).format('YYYY-MM-DD') == $date().format('YYYY-MM-DD')">
            <small class="text-sm font-normal">{{ $t("It can take up to 5 minutes for the gift card to be received") }}</small>
          </div>
        </div>

        <hr class="hr" />

        <div v-if="order.discount" class="mt-5 mb-5">
          <div class="flex justify-between mb-2">
            <div class="text-gray-700">{{ $t("Subtotal") }}</div>
            <div>
              {{ order.subtotal | formatCurrency(order.currency) }}
            </div>
          </div>

          <div class="flex justify-between mb-5">
            <div class="text-gray-700">{{ $t("Discount") }}</div>
            <div>-{{ order.discount | formatCurrency(order.currency) }}</div>
          </div>

          <hr class="hr" />
        </div>

        <div class="flex justify-between text-xl font-semibold mt-5 mb-5">
          <div>{{ $t("Total price") }}</div>
          <div>{{ order.total | formatCurrency(order.currency) }}</div>
        </div>

        <hr class="hr" />

        <div class="text-center pb-8 mt-8 mb-5">
          <p class="mb-2 leading-relaxed">
            {{ $t("Make someone you love happy with a gift card for Zalando - Europe's largest fashion platform.") }}
          </p>
          <p class="mb-2 leading-relaxed">
            <p>{{ $t("Here, the recipient can freely choose fashionable clothing from thousands of brands.") }}<br></p>
            <p style="font-size:20px; font-weight: 600; text-decoration: underline;">{{ $t("Please note the following conditions:") }}</p><br>
            <p>{{ $t("There are a few categories that do not fall under 'fashion,' such as:") }} </p><br>
            <p>{{ $t("Jewellery and watches, Home, lifestyle and electronics, Sports equipment, Travel bags and wallets, Beauty and makeup.") }}</p><br>
            <p><a class="underline" target="_blank" href="https://www.zalando.dk/vilkar-gavekort">{{ $t("Read more here") }}</a></p>
          </p>

          <p class="mb-2 leading-relaxed">
            {{ $t("The gift card will be sent directly to the recipient's email address on the selected delivery date.") }}
          </p>
          <p class="leading-relaxed">
            {{ $t("Please note that there is a DKK 1,500 limit per purchase. When you need to use the gift card at Zalando, you must also be aware that several gift cards can be redeemed per purchase up to a total maximum value of DKK 1,500. A possible remaining amount is made available for future purchases.") }}<br>
            {{ $t("The maximum value of GiftCards that can be used per month per end customer is limited to € 5.000. The maximum value of Gift Cards that can be connected to your customer account per calendar month is limited to € 5,000,-.") }}
          </p>
        </div>
      </div>

      <div class="text-center">
        <inertia-link :href="route('order.create')" class="button">
          {{ $t("Order one more gift card") }}
        </inertia-link>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import store from "@/store";

export default {
  name: "OrderSuccess",
  layout: Layout,

  props: {
    order: Object
  },

  mounted() {
    window.hlconversion = {
      orderId: this.order.order_id,
      orderSubTotal: this.order.total,
      orderCurrencyCode: this.order.currency
    };

    store.clear();
  }
};
</script>

